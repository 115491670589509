import React from "react";

export default function Education() {
    return (
        <div
        id="education"
        class="bg-[#FFD8A9] h-50 my-6 py-8 rounded-lg border-4"
      >
         <div class="flex justify-center">
          <h3 class="border-4 w-25  border-dashed px-4 py-2 font-bold text-md">Education</h3>
        </div>

        <details class="px-6 py-4">
          <summary class="font-bold text-2xl text-gray-800">
             Blucrest University College - 2022
          </summary>
          <p>
            I not attending Blucrest and I am just testing things out.
          </p>
        </details>

        <details class="px-6 py-4">
          <summary class="font-bold text-2xl text-gray-800">
             Blucrest University College - 2022
          </summary>
          <p>
            I not attending Blucrest and I am just testing things out.
          </p>
        </details>

        <details class="px-6 py-4">
          <summary class="font-bold text-2xl text-gray-800">
             Blucrest University College - 2022
          </summary>
          <p>
            I not attending Blucrest and I am just testing things out.
          </p>
        </details>

        <details class="px-6 py-4">
          <summary class="font-bold text-2xl text-gray-800">
             Blucrest University College - 2022
          </summary>
          <p>
            I not attending Blucrest and I am just testing things out.
          </p>
        </details>

      </div>
    )
}